/* eslint-disable react/prop-types */
import React from "react";
import { Helmet } from "react-helmet";
// import tokensoft_sale from "../img/tokensoft-sale.png";


export default function PublicSale() {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="/tailwind.min.css" />
        <link rel="stylesheet" href="/waitlist.css" />
     
      </Helmet>
      <section className="sm:mt-[-128px] sm:pt-[128px] pt-10 py-20 bg-cover bg-center sm:bg-[url('./img/roadmap_bg.jpg')]">
        <div className="container mx-auto px-10 lg:py-20">
          <div className="">
          <h1 className="pb-5 sm:pt-20 header-title text-4xl md:text-4xl leading-none lg:leading-tight">
                The LAOS Network Token Pre-Sale is now closed
              </h1>
          {/* <div className="flex flex-col sm:flex-row">
            <a
              href="https://laosnetwork.tokensoft.io"
              className="border bg-fvyellow border-fvyellow text-fvpurple ml-0 m-2 px-8 py-3 rounded transition duration-300 ease-in-out hover:bg-transparent hover:text-fvyellow flex-grow sm:flex-grow-0"
            >
              Register now with TokenSoft
            </a>
            <a
              href="https://bit2me.com/es/suite/launchpad/project/laos?utm_source=laosnetwork&utm_medium=referral&utm_campaign=launchpadlaos"
              className="border bg-fvyellow border-fvyellow text-fvpurple ml-0 m-2 px-8 py-3 rounded transition duration-300 ease-in-out hover:bg-transparent hover:text-fvyellow flex-grow sm:flex-grow-0"
            >
              Register now with Bit2Me
            </a>
          </div> */}
          </div>
        </div>
      </section>

      <section
        style={{
          backgroundImage: `linear-gradient(144deg, #25143b 21.25%, #613d93 78.75%)`,
          padding: "0",
          marginTop: "0",
        }}
      >
        {/* <CrowdloanComponent /> */}
      </section>
      <div className="flex justify-center items-center ">
        <section
          className="py-12 w-screen "
          style={{
            backgroundImage: `linear-gradient(144deg, #25143b 21.25%, #613d93 78.75%)`,
          }}
        >
          <div className=" px-10 xl:px-32 2xl:px-96">
            {/* <h2 className="text-3xl md:text-3xl text-center brandColor mb-5">
              LAOS Network Public Sale
            </h2> */}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-3 mb-10">
              <div className="bg-fvdarkpurple py-10 px-3 text-center rounded-lg border-2 border-fvgreypurple">
                <h3 className="text-2xl card-title text-fvyellow">
                  Purchase Period
                </h3>
                <p className="text-m">
                  July 11th to 15th, 2024
                </p>
              </div>
              <div className="bg-fvdarkpurple py-10 px-3 text-center rounded-lg border-2 border-fvgreypurple">
                <h3 className="text-2xl card-title text-fvyellow">
                  Price
                </h3>
                <p className="text-m">
                  $0.08 per token
                </p>
              </div>
              <div className="bg-fvdarkpurple py-10 px-3 text-center rounded-lg border-2 border-fvgreypurple">
                <h3 className="text-2xl card-title text-fvyellow">
                  Vesting
                </h3>
                <p className="text-m">
                  No cliff, 12 month linear vesting
                </p>
              </div>
              <div className="bg-fvdarkpurple py-10 px-3 text-center rounded-lg border-2 border-fvgreypurple">
                <h3 className="text-2xl card-title text-fvyellow">
                  Tokenomics
                </h3>
                <p className="text-m">
                  Read <a className="text-fvyellow hover:text-fvyellow hover:underline" href="https://docs.laosnetwork.io/tokenomics/laos-utility-token/">here</a>
                </p>
              </div>
            </div>
            
            <p className="mb-4 text-fvyellow">
            The LAOS Network token pre-sale is now closed and all tokens have been distributed to purchasing addresses.
            </p>
            <p>
              Please visit <a href="https://vesting.laosnetwork.io">here</a> to connect your wallet and see the status of your tokens.
            </p>
            
          </div>
        </section>
      </div>
    </>
  );
}
