import i_main_image from "./img/FO_logo.jpg"
import i_slides_image1 from "./img/1.jpg"
import i_slides_image2 from "./img/2.jpg"
import i_slides_image3 from "./img/3.jpg"
import i_slides_image4 from "./img/4.jpg"
import i_slides_image5 from "./img/5.jpg"
import i_slides_image6 from "./img/6.jpg"
import i_slides_image7 from "./img/7.jpg"
import i_slides_image8 from "./img/8.jpg"


var CaseStudyData = {
  release: "Coming Q4 2024",
  title: "FuriosAO",
  text: `FúriusAO is a 2D MMORPG set in a medieval world where players choose their city, race,
    and class to embark on thrilling adventures in dungeons, forests, seas, and deserts.`,
  main_image: i_main_image,
  button_text: "More info",
  button_link: "https://furiusao.com.ar/",
  developer: "FuriosAO",
  genre: "MMORPG",
  OS: "Desktop/Mobile",
  slider_images: [
    i_slides_image1,
    i_slides_image2,
    i_slides_image3,
    i_slides_image4,
    i_slides_image5,
    i_slides_image6,
    i_slides_image7,
    i_slides_image8,
  ],
  body_title: "Turn their achievements into assets with real economic value",
  body_text_paras: [
    `FúriusAO is a 2D Argentine MMORPG set in a medieval world where players choose their city, race,
and class to embark on thrilling adventures in dungeons, forests, seas, and deserts. In this universe,
they gather resources, craft tools, forge weapons, and learn spells to survive, progress, and excel in
their trade. With the integration of LAOS Network's NFT technology, players can now own unique and
exclusive items, conduct secure and transparent transactions, and turn their achievements into
assets with real economic value, elevating the gaming experience to a new level.`,
    `Every character in FúriusAO has a race that defines their physical form and a class that determines
their primary skills in the game. These initial choices, combined with the experience accumulated
throughout their journey, shape each player's unique development. The classes are divided into
workers and fighters: workers gather essential resources such as fish, wood, hides, or minerals, while
fighters specialize in combat, either using Mana or not. Thanks to the integration of LAOS Network's NFT technology, resources gathered and items crafted
by worker classes can become unique assets, ensuring their authenticity and allowing players to
trade them both within and outside the game. This adds a strategic layer, as the developed skills not
only empower the character but also create real income opportunities.`,
    `The integration of LAOS Network not only enhances the in-game economy but also fosters greater
cooperation among players and factions by turning resources and achievements into unique and
valuable assets. This transforms FúriusAO into a world where every action and decision can have a
tangible impact, both in the game community and in the real economy.`,
  ],
}
export default CaseStudyData;