import i_slides_image1 from "./img/1.jpg"
import i_slides_image2 from "./img/2.jpg"
import i_slides_image3 from "./img/3.jpg"
import i_slides_image4 from "./img/4.jpg"


var CaseStudyData = {
  release: "Early Access",
  title: "Metaclash: Digital Avatars of Destruction",
  text: `MetaClash: Digital Avatars of Destruction is a competitive third-person shooter 
  centered on vehicular combat. Players engage in team-based battles across various game 
  modes, utilizing a modular vehicle system that allows for upgrades and strategic 
  customization to dominate the arena.
`,
  main_image: i_slides_image1,
  button_text: "More info",
  button_link: "https://www.metaclash.online",
  developer: "Metaclash",
  genre: "Vehicle Combat",
  OS: "PC ",
  slider_images: [
    i_slides_image1,
    i_slides_image2,
    i_slides_image3,
    i_slides_image4,
  ],
  body_title: "Driving Into the Future of Gaming",
  body_text_paras: [
    `MetaClash: Digital Avatars of Destruction is a competitive third-person shooter 
    centered on vehicular combat. Players engage in team-based battles across various 
    game modes, utilizing a modular vehicle system that allows for upgrades and 
    strategic customization to dominate the arena.`,
    `MetaClash takes a Web2.5 approach, ensuring blockchain features remain optional 
    for traditional gamers while offering unique Web3 mechanics for blockchain enthusiasts. 
    Players can mint their in-game assets as NFTs, unlocking opportunities for trading and 
    access to exclusive events.`,
    `The game is integrating PFP NFT collections, enabling players to showcase their 
    favorite NFTs as in-game cosmetics. This fosters deeper connections with their communities, 
    while increasing competitiveness between collections.`,
    `MetaClash is available on trusted platforms such as the Epic Games Store and Hyperplay, 
    supported by partners including Sequence, Soneium, and Beam.`,
  ],
}
export default CaseStudyData;