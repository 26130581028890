/* eslint-disable react/prop-types */ 
import React, { useState } from 'react';
import LinkPanelArrow from './components/LinkPanelArrow';
import DeveloperFAQ from './components/DeveloperFAQ';
import Newsletter from './components/Newsletter';
import hero_image from '../img/GRANTS_IMAGE_HERO.png';
import image_190 from '../img/100_500K.png';
import icon_pbl from '../img/icon_powered_by_laos.png';
import icon_ldg from '../img/icon_laos_developers.png';
// import image_coins from '../img/COINS_MILESTONES.png';
// import image_arrow from '../img/GROWTH.png';
// import image_rocket from '../img/ROCKET.png';

// Email obfuscation component
const ObfuscatedEmail = ({ email, subject, className }) => {
    const [revealed, setRevealed] = useState(false);
    const [username, domain] = email.split('@');
  
    const handleClick = (e) => {
      if (!revealed) {
        e.preventDefault();
        setRevealed(true);
        return;
      }
  
      // Construct the actual mailto link when clicked
      const mailtoUrl = `mailto:${email}${subject ? `?subject=${encodeURIComponent(subject)}` : ''}`;
      window.location.href = mailtoUrl;
    };
  
    return (
    <a
        href={`mailto:${revealed ? email : ""}`}
        onClick={handleClick}
        className={className || "text-fvyellow hover:underline text-lg"}
        aria-label="Contact email"
      >
        {revealed ? email : (
          <>
            <span>{username}</span>
            <span style={{ display: 'none' }}>[hidden]</span>
            <span>@</span>
            <span>{domain}</span>
          </>
        )}
      </a>
    );
  };
  

const CONTENT = {
    link_arrows: [
        {
            title: "Grant Application Template",
            link: "/downloads/LAOS_Grant_Application_Form.docx" 
        },
        {
            title: "Sample Grant Application",
            link: "/downloads/LAOS_Grant_Application_Form_Sample.pdf"
        },
        {
            title: "LAOS Tokenomics",
            link: "https://docs.laosnetwork.io/tokenomics/laos-utility-token/"
        },
        {
            title: "Builder's Guide",
            link: "/build"
        },
        {
            title: "Join Our Community",
            link: "/community"
        },
    ],
    faq: [
        {
            title:"Which grant track should I choose?",
            text: "Projects just starting out, or projects still in the early stages of growth, are better to apply for the Powered-By-LAOS grant. While the award is smaller, there is a much less rigorous application process and a greater chance of success. Larger, more established project, promising large amount of transactions on LAOS and/or community growth, could apply for the LAOS Developers Grant."
        },
        {
            title:"How much is a typical grant?",
            text: "Powered-By-LAOS grants are fixed at 100K $LAOS Tokens (not USD equivalent). For LAOS Developer grants, the amount varies based on each project's potential impact, up to 500K $LAOS."
        },
        {
            title:"Are LAOS grants compatible with those from other chains?",
            text: "In many cases, LAOS grants are usually compatible with others. The program is designed to be complementary to grants from other sources."
        },
        {
            title:"Do you offer grants in other denominations, such as USDT?",
            text: "At the moment, the program is only awarding grants in $LAOS token."
        },
        {
            title:"If my project is selected, when will I receive $LAOS?",
            text: "All grants are associated with milestones. Corresponding $LAOS tokens are awarded based on these milestones being completed."
        },
        {
            title:"Will I be able to exchange awarded $LAOS for other currencies?",
            text: "The majority of all grants are associated with a vesting procedure which will unlock tradeable token over time. The $LAOS token will be available for trading on several exchanges."
        },
        {
            title:"Why can't I exchange all my awarded $LAOS straight away?",
            text: "LAOS Network is not a publisher. The grants program is not designed to be the primary funding source for any project; rather it should be thought of a complementary incentive to encourage builders to take advantage of the features of the network."
        },
    ]
}

const Grants = () => {
    const contactEmail = "info@laosnetwork.io";

    return (
    <>
    <section className="">
        <div className="container mx-auto pb-10">
            <div className="grid md:grid-cols-2 place-items-center">
                <div className="pt-10 md:pl-10 text-center md:text-left">
                    <h1 className="text-fvyellow pb-3 header-title text-3xl md:text-5xl leading-none lg:leading-tight">LAOS Network Grants Program</h1>
                    <div className='text-xl md:text-2xl w-3/4 md:w-full mx-auto'>
                    190 Million $LAOS unlocked over two years to fund community growth. 
                    </div>
                    <div className='text-xl md:text-2xl my-8 w-3/4 md:w-full mx-auto'>
                    Level-up your game or DApp with the LAOS Network Grants Program, supporting projects that harness the capabilities of the LAOS platform, as well as contributing to the growth and enrichment of our community.
                    </div>
                    <div className='mb-8'>
                        <a
                        href="#section-apply"
                        className="border border-fvyellow text-fvyellow px-8 py-3 rounded transition duration-300 ease-in-out hover:bg-fvyellow hover:text-fvpurple"
                        >
                        Apply Now
                        </a>
                    </div>
                </div>
                <div className="order-first md:order-last">
                    <img src={hero_image} alt="grants" />
                </div>
            </div>
        </div>
    </section >
    <section className='background-purple-box-radial py-8'>
        <h2 className="section-title text-3xl md:text-4xl my-10 text-center">At a glance</h2>
        <hr className="w-[15%] h-[2px] my-4 rounded border-0 hr-colored mx-auto" />
        <div className="flex flex-col items-center justify-center">
            <div className="px-4 md:px-0 md:pb-4">
                <img src={image_190} alt="190" />
            </div>
            {/* <div className="container px-8 md:px-0 md:w-1/2 text-md md:text:2xl text-center">
                 Two grant tracks: the <span className="text-fvyellow">Powered By LAOS</span> track for projects wishing to 
                 get started with LAOS; and <br /> the <span className="text-fvyellow">LAOS Developers Grant</span> for projects 
                 promising greater in terms of chain usage and community growth.
            </div>
            <div className="container px-4 pt-4 md:px-0 md:w-1/2 text-md md:text:2xl text-center">
                Priority given to projects already launched or lauching soon.
            </div> */}
            
        </div>

        <div id="section-apply" className="container mx-auto pb-10 px-2">
            <h2 className="section-title text-3xl md:text-4xl mb-10 mt-16 text-center">Grant Tracks</h2>
            <hr className="w-[15%] h-[2px] my-4 rounded border-0 hr-colored mx-auto" />
            <div className="grid grid-cols-1 md:grid-cols-2 md:w-3/4 mx-auto space-y-4 md:space-x-4 md:space-y-0 mt-16">
                <div className="rounded-2xl bg-gradient-to-br from-fvyellow via-fvorange to-fvpurple p-[2px]">
                    <div className="w-full h-full rounded-2xl items-center justify-center 
                            bg-fvpurple">
                        <div className="flex flex-col items-center justify-items-center p-8">
                            <img className="w-[130px] mb-8" src={icon_pbl} alt="powered by laos" />
                            <div className="mb-4 text-fvyellow text-2xl">
                                Powered-By-LAOS Grant    
                            </div>
                            <div className="mb-4 text-center">
                            A plug-n-play award for projects wishing to get started quickly with LAOS.    
                            </div>
                            <ul className='list-disc list-outside ms-8 space-y-2'>
                                <li>Standard grant size of 100K&#42; $LAOS tokens, with vesting.</li>
                                <li>Tokens allocated at three Milestones: Announce (10%), Beta (40%),
                                    and Launch (50%).
                                </li>
                                <li>Straighforward application process, simply email us with your project details.</li>
                            </ul>
                            <div className='my-8 text-center'>
                                <p className="mb-3">To apply, email us at:</p>
                                <ObfuscatedEmail 
                                  email={contactEmail} 
                                  subject="Powered-By-LAOS Grant Application" 
                                  className="text-fvyellow hover:underline text-lg"
                                />
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="rounded-2xl bg-gradient-to-br from-fvyellow via-fvorange to-fvpurple p-[2px]">
                    <div className="w-full h-full rounded-2xl items-center justify-center 
                            bg-fvpurple">
                        <div className="flex flex-col items-center justify-items-center p-8">
                            <img className="w-[130px] mb-8" src={icon_ldg} alt="laos developers grants" />
                            <div className="mb-4 text-2xl">
                                LAOS Developers Grant    
                            </div>
                            <div className="mb-4 text-center">
                            For bigger projects that promise a large numbers of transactions and community growth.    
                            </div>
                            <ul className='list-disc list-outside ms-8 space-y-2'>
                                <li>Awards of up to 500K&#42; $LAOS tokens, with vesting.</li>
                                <li>Flexible Milestones, with most of grant unlocked upon hitting
                                    target usage and community KPIs.
                                </li>
                                <li><a className="text-fvyellow hover:underline" href="/downloads/LAOS_Grant_Application_Form.docx">Download and complete the application</a></li>
                                <li><a className="text-fvyellow hover:underline" href="/downloads/LAOS_Grant_Application_Form_Sample.pdf">View a sample application</a></li>
                            </ul>
                            <div className='my-8 text-center'>
                                <p className="mb-3">To apply, email us at:</p>
                                <ObfuscatedEmail 
                                  email={contactEmail} 
                                  subject="LAOS Developers Grant Application" 
                                  className="text-fvyellow hover:underline text-lg" 
                                />
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
            <div className="text-xs w-50 mx-auto my-2 text-center">
                &#42;Note: grant amounts are specified in number of LAOS tokens, not equivalent in USDT or any other currency.
            </div>
        </div>

        <div className="container mx-auto pb-10 px-2 md:px-0 md:w-3/4 mt-8">
            <div className="rounded-2xl bg-gradient-to-br from-fvyellow via-fvorange to-fvpurple p-[2px]">
                <div className="w-full h-full rounded-2xl bg-fvpurple p-8">
                    <h2 className="section-title text-3xl md:text-4xl mb-8 text-center">How to Apply</h2>
                    <hr className="w-[15%] h-[2px] my-4 rounded border-0 hr-colored mx-auto" />
                    <div className="text-lg md:text-xl my-6 text-center">
                        Please send an email to <ObfuscatedEmail 
                          email={contactEmail} 
                          className="text-fvyellow hover:underline" 
                        /> with the following information:
                    </div>
                    <div className="md:w-3/4 mx-auto">
                        <ul className='list-disc list-outside ms-8 space-y-3'>
                            <li>Project name</li>
                            <li>Link to website</li>
                            <li>Blockchain in which the project is or will be built on (if known)</li>
                            <li>Current stage of the project (development/alpha/beta/live)</li>
                            <li>Grant track - Please specify which track you are applying for:
                                <ul className="list-disc list-outside ms-8 mt-2">
                                    <li>Powered-By-LAOS: smaller, standard grant, more likely to be awarded</li>
                                    <li>LAOS Developers: for larger projects promising significant usage and community growth</li>
                                </ul>
                            </li>
                            <li>Link to demo, prototype, MVP or deck</li>
                        </ul>
                    </div>
                    <div className="text-lg md:text-xl mt-8 text-center">
                        For LAOS Developers grants, we recommend also downloading and completing the <a className="text-fvyellow hover:underline" href="/downloads/LAOS_Grant_Application_Form.docx">application form</a> and attaching it to your email.
                    </div>
                </div>
            </div>
        </div>
    </section> 
    <section className='my-8'>
        <h2 className="section-title text-4xl mb-5 text-center">Grants FAQ</h2>
        <hr className="w-[15%] h-[2px] my-4 mx-auto rounded border-0 hr-colored mb-16" />
        <div className="container mx-auto flex flex-col">
            {CONTENT.faq.map((item,i) => {
                return (
                    <DeveloperFAQ key={i} title={item.title} text={item.text} />
                )
            })}
        </div>
    </section>
    <section>
        <div className="bg-gradient-to-r from-fvdarkpurple to-fvpurple py-8">
            <h2 className="section-title text-4xl mb-5 text-center">Resources</h2>
            <hr className="w-[15%] h-[2px] my-4 mx-auto rounded border-0 hr-colored mb-8" />
            <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {CONTENT.link_arrows.map((item, i) => {
                    return(
                        <LinkPanelArrow text={item.title} link={item.link} key={i} />
                    )
                })}
            </div>
        </div>
    </section>
    <section>
            <Newsletter />
    </section>
    </>
    );
};

export default Grants;