/* eslint-disable react/prop-types */
import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
// import Blog from "./pages/Blog";
import BlogPost from "./pages/BlogPost";
import About from "./pages/About";
import Grants from "./pages/Grants";
import NoPage from "./pages/NoPage";
import Redirect from "./pages/Redirect";
import Crowdloan from "./pages/Crowdloan";
import WalletRoadmap from "./pages/WalletRoadmap";
import PublicSale from "./pages/PublicSale";
import RoadmapAfterTGE from "./pages/RoadmapAfterTGE";
import Build from "./pages/Build";
import Community from "./pages/Community";
import CaseStudy from "./pages/case_studies/CaseStudy";
import Token from "./pages/Token";
import PrivacyPolicy from "./pages/PrivacyPolicy";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route
            path="/blog"
            element={<Redirect url={"https://medium.com/laosnetwork"} />}
          />
          <Route path="build" element={<Build />} />
          <Route path="community" element={<Community />} />
          <Route path="blog/:id" element={<BlogPost />} />
          <Route path="about" element={<About />} />
          <Route path="grant-program" element={<Grants />} />
          <Route path="*" element={<NoPage />} />
          {/* <Route path="case-studies" element={<CaseStudy />} /> */}
          <Route path="case-studies/:study" element={<CaseStudy />} />
          <Route path="laos-token" element={<Token />} />
          <Route path="privacy" element={<PrivacyPolicy />} />

          <Route
            path="/bridgeless-minting"
            element={
              <Redirect
                url={
                  "https://www.youtube.com/playlist?list=PLPAfDU_4G35buAx0apUk6cq8PtRkMMKQe"
                }
              />
            }
          />
          <Route path="waitlist" element={<Crowdloan />} />
          <Route path="crowdloan" element={<Crowdloan />} />
          <Route
            path="wallet-compatibility-roadmap"
            element={<WalletRoadmap />}
          />
          <Route path="token-sale" element={<PublicSale />} />
          <Route path="roadmap-after-tge" element={<RoadmapAfterTGE />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
