import React, { Component } from 'react';
import icon1 from "../img/icon_1.png"
import icon2 from "../img/icon_2.png"
import icon3 from "../img/icon_3.png"
import LinkPanelArrow from './components/LinkPanelArrow';
import GrantPanel from './components/GrantPanel';
import Newsletter from './components/Newsletter';
import DeveloperFAQ from './components/DeveloperFAQ';

const CONTENT = {
    main_title: "Mint NFTs on any chain, without congestion, at a fraction of the cost",
    main_text: `LAOS Network is the Layer-1 blockchain that allows developers to mint assets   
                on any EVM-compatible blockchain, without paying the gas fees of that chain, 
                without causing congestion, and without requiring users to change network.`,
    button_left: {
        text: "Documentation",
        link: "https://docs.laosnetwork.io"
    },
    button_right: {
        text: "Grant Program",
        link: "/grant-program"
    },
    developer_tools: [
        {
            title: "How does LAOS work?",
            text: "Learn how LAOS connects without bridges to EVM chains and permits NFT minting at scale.",
            link: "https://docs.laosnetwork.io/introduction/goals",
            icon: icon1,
            icon_right: false
        },
        {
            title: "Bridgeless Minting Explanation",
            text: "A deep dive on how Bridgeless Minting creates NFTs on any EVM, without paying the gas fees of that chain.",
            link: "https://docs.laosnetwork.io/bridgeless-minting/introduction",
            icon: icon2,
            icon_right: true
        },
        {
            title: "Quickstart Developer Scripts",
            text: "Use these simple script examples to mint assets with LAOS on any EVM-compatible chain.",
            link: "https://github.com/freeverseio/laos-examples",
            icon: icon3,
            icon_right: false
        },
    ],
    link_arrows: [
        {
            title: "Technical Whitepaper",
            link: "https://github.com/freeverseio/laos-whitepaper/blob/main/laos.pdf" 
        },
        {
            title: "Block Explorer",
            link: "https://blockscout.laos.laosfoundation.io/"
        },
        {
            title: "Documentation",
            link: "https://docs.laosnetwork.io"
        },
        {
            title: "Tokenomics",
            link: "https://docs.laosnetwork.io/tokenomics/laos-utility-token/"
        },
        {
            title: "Get Started Guide",
            link: "https://github.com/freeverseio/laos-examples"
        },
        {
            title: "Grant Program",
            link: "/grant-program"
        },
    ],
    faq: [
        {
            title:"How does LAOS Mint Assets on Ethereum and other EVM chains?",
            text: "LAOS Network uses ‘Bridgeless Minting’ to mint assets on other chains. Our open-source uERC-721 smart contract is deployed once to the EVM chain of your choice. Its tokenURI property is set to a sibling collection created in LAOS. Thereafter, every time an asset is minted in the LAOS collection, it appears in the collection on the EVM chain."
        },
        {
            title:"Is there a live demo of Bridgeless Minting?",
            text: "Yes. You can visit https://testnet.apps.laosnetwork.io/ to grab test tokens from our faucet, try a live demo in our testnet, and mint assets in our public collections in Ethereum or Polygon."
        },
        {
            title:"Do I need tokens to build on LAOS?",
            text: "Yes, you will need LAOS tokens to mint assets. You will shortly be able purchase tokens on exchanges, or you can also apply to our grant program."
        },
        {
            title:"Are LAOS grants compatible with those from other chains?",
            text: "Yes. LAOS grants are are 100% compatible with other EVM-compatible chains."
        }
    ]
}

class Build extends Component {
    render() {
        return (
        <>
        <section>
            <div className="bg-[radial-gradient(ellipse_at_bottom,_var(--tw-gradient-stops))] from-fvorange from-0% via-fvpurple to-fvmidpurple to-50% py-20">
                <div className="w-3/4 sm:w-1/3 mx-auto py-10 flex flex-col">
                    <h1 className="text-center text-fvyellow font-bold text-2xl sm:text-4xl mb-8">{CONTENT.main_title}</h1>
                    <h5 className="text-center sm:text-lg mb-8">{CONTENT.main_text}</h5>
                    <div className="flex flex-col space-y-10 sm:space-y-0 sm:flex-row justify-center sm:space-x-16">
                        <div className="text-center">
                            <a
                            href={CONTENT.button_left.link}
                            className="border border-fvyellow text-fvyellow px-8 py-3 rounded transition duration-300 ease-in-out hover:bg-fvyellow hover:text-fvpurple"
                            >
                            {CONTENT.button_left.text}
                            </a>
                        </div>
                        <div className="text-center">    
                            <a
                            href="/grant-program"
                            className="border border-fvyellow text-fvyellow px-8 py-3 rounded transition duration-300 ease-in-out hover:bg-fvyellow hover:text-fvpurple"
                            >
                            Grant Program
                            </a>
                        </div>
                    </div>
                </div> 
            </div>  
        </section>
        <section>
            <div className='bg-[#211434] pt-8'>
                <div className="container mx-auto px-10 py-10">
                    <h2 className="section-title text-4xl mb-5 text-center">Developer Tools</h2>
                    <hr className="w-[15%] h-[2px] my-4 mx-auto rounded border-0 hr-colored" />
                    {CONTENT.developer_tools.map((item, i) => {
                        return (
                            <div key={i} className={item.icon_right ? 
                                "flex flex-col sm:flex-row-reverse justify-center w-1/2 mx-auto py-10" :
                                "flex flex-col sm:flex-row justify-center w-1/2 mx-auto py-10"}>
                                <div className="">
                                    <img src={item.icon} alt="laosicon" className='w-1/2 mx-auto sm:mr-auto' />
                                </div>
                                <div className="flex flex-col">
                                    <div className="text-fvorange text-lg font-bold mb-2 text-center sm:text-left">{item.title}</div>
                                    <div className="text-center sm:text-left">{item.text}</div>
                                    <div className="mt-8">
                                        <a
                                        href={item.link}
                                        className="border bg-fvyellow border-fvyellow text-fvpurple px-8 py-3 rounded transition duration-300 ease-in-out hover:bg-transparent hover:text-fvyellow"
                                        >
                                        Learn More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div> 
        </section>
        <section>
            <div className="bg-gradient-to-r from-fvdarkpurple to-fvpurple py-8">
                <h2 className="section-title text-4xl mb-5 text-center">Developer Resources</h2>
                <hr className="w-[15%] h-[2px] my-4 mx-auto rounded border-0 hr-colored" />
                <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                    {CONTENT.link_arrows.map((item, i) => {
                        return(
                            <LinkPanelArrow text={item.title} link={item.link} key={i} />
                        )
                    })}
                </div>
            </div>
        </section>
        <section>
            <GrantPanel />
        </section>
        
        <section className='mb-8'>
            <h2 className="section-title text-4xl mb-5 text-center">Developer FAQ</h2>
            <hr className="w-[15%] h-[2px] my-4 mx-auto rounded border-0 hr-colored" />
            <div className="container mx-auto flex flex-col">
                {CONTENT.faq.map((item,i) => {
                    return (
                        <DeveloperFAQ key={i} title={item.title} text={item.text} />
                    )
                })}
           </div>
        </section>
        <section>
            <Newsletter />
        </section>
        </>
        );
    }
}

export default Build;