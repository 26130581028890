import i_main_image from "./img/DARKRALLY_LOGO_K.jpg"
import i_slides_image1 from "./img/1.jpg"
import i_slides_image2 from "./img/2.jpg"
import i_slides_image3 from "./img/3.jpg"
import i_slides_image4 from "./img/4.jpg"
import i_slides_image5 from "./img/5.jpg"
import i_slides_image6 from "./img/6.jpg"

var CaseStudyData = {
  release: "Our Now",
  title: "Dark Rally",
  text: `Dark Rally is web 3 racing game. The most epic race on earth, man and machines against
   miles of desert, and crazy racers along 14 levels of adrenaline. `,
  main_image: i_main_image,
  button_text: "More info",
  button_link: "https://darkrally.com",
  developer: "Plop Studios",
  genre: "Racing",
  OS: "Desktop/Mobile",
  slider_images: [
    i_slides_image1,
    i_slides_image2,
    i_slides_image3,
    i_slides_image4,
    i_slides_image5,
    i_slides_image6,
  ],
  body_title: "The most epic race on earth.",
  body_text_paras: [
    `The most epic race on earth, man and machines against miles of desert and crazy racers along 14 levels of adrenaline. This game reached 1.7MM total downloads on its first edition. It works on a smooth welcome zone where player receive 35,000 started Dark Coins (DRK) to purchase its first vehicle, the vehicle zone shows 5 categories TRUCKS, CARS, SSV (side by side vehicles), quads and bikes. `,
    ` Garage (Speed, Acceleration, Weight, handling and Turbo) and the Energy pack is for 03 free shifts of gameplay.
Player on first position unlocks next level and get the higher DRK reward, positions 2, 3, 4 and so, do not unlock next level, but receive rewards in Dark Coins (DRK)
`,
    `DRK allows purchasing more vehicles, enhance them, get more energy packs, purchase tickets for Worldwide Tournaments, rewards other players, and many more.`,
    `Dark Rally works over Sequence network, having a smooth blockchain immersion making easy to connect with crypto ecosystem. `,
    `Currently available on Google Play and AppStore, soon on Epic and Steam stores.`,
  ],
}
export default CaseStudyData;